/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { createMuiTheme } from "@material-ui/core/styles";
// import Header from "./header";
import "./layout.css";
import { Container } from "@material-ui/core";
import {ptBR} from "@material-ui/core/locale";
// import bg from "../images/bg.png";
import logoOmega from "../images/logo1.png";
// import { getData } from "../services/service";
import pacote from "../../package.json";
import SEO from "./seo";

const themeProps = {
  palette: {
    type: "light",
    primary: {
      main: "#75b756",
      contrastText: "white",
    },
    secondary: {
      // light: "#0066ff",
      main: "#ff1744",
      contrastText: "white",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Exo 2', Roboto, Verdana, Helvetica, Arial, sans-serif",
  },
};
// const initialTheme = createMuiTheme(themeProps);

const Layout = ({ children, mainClasses, easterEggColor, largeScreem }) => {
  const theme = useMemo(() => {
    themeProps.palette.primary.main = easterEggColor ? "#125d67" : "#75b756";
    return createMuiTheme(themeProps, ptBR);
  }, [easterEggColor]);

  return (
    <ThemeProvider theme={theme}>
      <link
        href={"https://cdn.omegaeducacional.com/fonts/exo2/exo2.css"}
        rel="stylesheet"
      />
      <style>
        {`html {
          background-color: ${theme.palette.primary.main};
        }`}
      </style>
      <main className={mainClasses}>
        <SEO
          meta={[
            {
              name: "color-theme",
              content: easterEggColor ? "#125d67" : "#75b756",
            },
          ]}
        />
        <Container maxWidth="xl">{children}</Container>
      </main>
      <footer className="footer">
        <img src={logoOmega} alt="logo cliente" className={"logo"}></img>
        <span className="copyright">
          © Copyright 2007 - {new Date().getFullYear()}. Omega Sistemas - Todos
          Direitos Reservados
        </span>
        <span className="version" title="Versão do site">
          v{pacote.version}
        </span>
      </footer>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainClasses: PropTypes.string,
  easterEggColor: PropTypes.bool,
};

export default Layout;
